import React from 'react'
import Layout from '../components/layout';
import Shop from "../images/shop.jpeg"


const  ContactPage = () => (
        <Layout>
        <div className="contact container">

    <h1>Contact Us</h1>
    <div className="contactContent row">
        <div className="contact1 col-4">
            <img src={Shop} alt="shop" className="shop" />
        </div>
        {/* <div className="contact2 col-4">2</div> */}
        <div className="contact3 col-4">
           <b>Winter Hours: </b>
           <br/>
           <p className="hours">
           Mon-Fri: 09:00am - 04:00pm
           <br/>
           Sat: 09:00am - 02:00pm
           <br/>
           Sun: Closed
           </p>
           <div className="moreInfo">
           <p className="contactAd"><b className="contactAB">Address: </b>2220 Old Millsap Hwy, Mineral Wells, TX 76067</p> 
           <p className="contactPh"><b className="contactPB">Phone: </b><a href="tel:9406824724">940-682-4724</a></p> 
           </div>
        </div>

    </div>
        </div>
        </Layout>
    )


export default ContactPage;
